//TODO: Better handle state, preserve across page transitions
/*@ngInject*/
function ResultsCtrl(
  $timeout,
  $routeParams,
  $log,
  $location,
  $q,
  jobTracker,
  userProfile
) {
  this.searchQuery = null;
  this.searchSort = null;
  this.searchFrom = null;
  this.searchSize = null;
  this.err = null;
  this.searchMode = false;

  // defaults
  this.type = "completed";
  this.failBanner = "No completed jobs found";
  this.transformedSearchQuery = null;

  this.profile = userProfile;
  this.jobResultsFetched = false;

  this.updateError = null;
  this.fetchingResults = false;

  Object.defineProperty(this, "jobBeingViewed", {
    get: () => (this._id ? jobTracker.jobs.all[this._id] : {})
  });

  this.hasJob = () => Object.keys(this.jobBeingViewed || {}).length;

  // Object.defineProperty(this, 'searchMode', {
  //   get: () => resultState.searchMode,
  // });
  this.searchSuggest = val => {
    this.rawQuery = val;
  };

  this.suggestions = [];

  this.suggestionsAvailable = (inputWord, suggestions) => {
    if (!suggestions.length && this.suggestions.length) {
      this.suggestions = suggestions.map(replacement => {
        const index = this.searchQuery.lastIndexOf(replacement[1]);
        return [
          this.searchQuery.substr(0, index) +
            replacement[0] +
            this.searchQuery.substr(
              index + replacement[1].length,
              this.searchQuery.length
            ),
          replacement[1],
          replacement[2]
        ];
      });

      return;
    }

    if (!suggestions.length) {
      return;
    }

    const index = this.searchQuery.lastIndexOf(inputWord);

    if (index === -1) {
      return;
    }

    this.suggestions = suggestions.map(replacement => {
      return [
        this.searchQuery.substr(0, index) +
          replacement[0] +
          this.searchQuery.substr(
            index + inputWord.length,
            this.searchQuery.length
          ),
        inputWord,
        replacement[1]
      ];
    });

    // console.info(
    //   "made suggestions",
    //   inputWord,
    //   index,
    //   inputWord.length,
    //   this.suggestions
    // );
  };

  this.submitQueryChange = value => {
    if (value === this.searchQuery) {
      return;
    }

    if (value === "") {
      // Empty queries never trigger onQueryUpdate, because the search component
      // gets destroyed
      this.onQueryUpdate(value);
    }

    this.searchQuery = value;
  };

  this.onQuerySuggestionChosen = queryWithSuggestion => {
    this.viewQuery = queryWithSuggestion;

    this.onQueryUpdate(queryWithSuggestion);
  };

  // TODO: move into separate component, duplicate of queue component
  this.checkingStatus = false;
  this.checkingStatusSuccess = false;
  this.checkingStatusError = false;

  this.checkIndexStatus = () => {
    this.checkingStatus = true;
    this.jobBeingViewed
      .$checkIndexStatus()
      .then(
        () => {
          this.checkingStatusSuccess = true;
        },
        err => {
          this.checkingStatusError = err;
        }
      )
      .finally(() => {
        $timeout(() => {
          this.checkingStatus = false;
          this.checkingStatusSuccess = false;
          // Keep error visible
        }, 500);
      });
  };

  this.updateLocation = (key, val) => {
    const params = $location.search();
    params[key] = val;

    $location.search(params);
  };

  this.onMissingIndex = () => {
    this.jobBeingViewed.setIndexMissing();
    this.viewQuery = null;
    this.searchQuery = null;
  };

  this.fetchingResults = false;

  this.onErrorShown = () => {
    this.clearJobBeingViewed();
    this.updateError = null;
  };

  this.updateJob = id => {
    if (!id) {
      $q.reject("id required in updateJob");
      return;
    }

    // We cannot pass a one-way bound job to the component from route resolver
    // and have reference changes propagate... annoying.
    // So we do this instead
    this.fetchingResults = true;
    this.updateError = false;

    //2nd argument (force == true) makes us get the full results
    //and makes jobTracker track this new data (basically including the results: property)
    jobTracker.initializeAsync().then(() =>
      jobTracker
        .getOneAsync(id, true)
        .then(updatedJob => {
          //By not setting jobBeingViewed, we can maintain a reference to the latest held by the job tracker
          this.jobResultsFetched = true;

          // Because this can be called from job chooser
          this._id = updatedJob._id;
          this.updateLocation("_id", updatedJob._id);

          if (!updatedJob.isIndexCompleted()) {
            this.checkIndexStatus();
          }
        })
        .catch(err => {
          this.updateError = err;

          $log.error(err);
        })
        .finally(() => {
          this.fetchingResults = false;
        })
    );
  };

  this.clearJobBeingViewed = () => {
    this._id = null;
    this.err = null;
    // resultState.toggleSearchMode(false);
    this.searchMode = false;
    this.searchQuery = null;
    this.searchSort = null;
    this.searchFrom = null;
    this.searchSize = null;
    this.jobResultsFetched = false;

    $location.search({
      _id: null
    });
  };

  this.$onInit = () => {
    jobTracker.initializeAsync().finally(() => {
      if ($routeParams.search) {
        if ($routeParams.size) {
          this.searchSize = $routeParams.size;
        }

        if ($routeParams.from) {
          this.searchFrom = $routeParams.from;
        }

        if ($routeParams.sort) {
          this.searchSort = $routeParams.sort;
        }

        if ($routeParams.q) {
          this.searchQuery = $routeParams.q;
        }

        this.searchMode = true;
      }
    });
  };

  this.$onChanges = changesObj => {
    // Check for a real value, so that we can go back to the viewed job
    // when navigating between pages
    if (!changesObj._id.currentValue) {
      this.clearJobBeingViewed();
    }

    if (
      changesObj._id &&
      changesObj._id.currentValue &&
      changesObj._id.currentValue !== changesObj._id.previousValue
    ) {
      this.updateJob(changesObj._id.currentValue);
    }

    if (changesObj.type && changesObj.type.currentValue) {
      this.failBanner = `No ${changesObj.type.currentValue} jobs found`;
    }
  };

  this.toggleSearchMode = () => {
    this.searchMode = !this.searchMode;
    // resultState.toggleSearchMode();
    $location.search({
      _id: this._id,
      search: this.searchMode
    });
  };

  // this.setSearchModeIfData = () => {
  //   if(this.searchQuery)
  // }
  this.onQueryUpdate = (q, sort, size, from) => {
    if (q === "") {
      $location.search({
        _id: this._id
      });
      return;
    }

    // // We keep our query, because the search component modifications to the query
    // // string that we pass it is completely deterministic from the search query
    // // passed to it by the consumer; it is modified to elastic search syntax
    // // which will look confusing to the user
    $location.search({
      _id: this._id,
      search: true,
      q,
      sort,
      size,
      from
    });
  };
}

angular
  .module("sq.jobs.results.component", [
    "sq.jobs.chooser.component",
    "sq.jobs.events.service",
    "sq.jobs.results.foamTree.component",
    "sq.jobs.upload.toS3.component",
    "sq.jobs.results.service",
    "sq.jobs.tracker.service",
    "sq.user.profile.service",
    "sq.jobs.infoCard.component",
    "sq.jobs.queue.status.component",
    "sq.jobs.model",
    "sq.jobs.results.search.suggest.component",
    "sq.jobs.results.search.hints.component",
    "sq.jobs.unauthorized.component",
    "sq.jobs.results.reindex.component"
  ])
  .component("sqJobResults", {
    bindings: {
      // The submission object (could be search or main job submission)
      // Angular does not like "_id" in the url...so yeah
      _id: "<id",
      type: "<"
      // comes from jobTracker, we expect to have a resolved list of jobs here
      // jobs : '<',
    }, // isolate scope
    templateUrl: "jobs/results/jobs.results.tpl.html",
    controller: ResultsCtrl
  });
